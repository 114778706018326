<template>
  <section class="flex-start flex-grow">
    <transition name="slide-fade">
      <keep-alive include="RetailSellerList">
        <router-view class="flex-grow" :cart-list="cartList"></router-view>
      </keep-alive>
    </transition>

    <!--购物车-->
    <shopping-cart
      :cart-list="cartList"
      permission="appRetailSellOrderCalc"
    ></shopping-cart>
  </section>
</template>
<script>
import ShoppingCart from "@/components/ShoppingCart";
import { shoppingCart } from "../../../api/cart";
import { mapActions } from "vuex";

export default {
  name: "home",
  data() {
    return {
      cartList: {} // 购物车列表
    };
  },
  components: {
    ShoppingCart: ShoppingCart
  },
  created() {
    if (this.$store.state.order.cartList.id) {
      this.getCartList(); // 获取单个购物车商品列表
    }
  },
  methods: {
    ...mapActions("order", ["setCartList"]),
    // 获取单个购物车商品列表
    getCartList() {
      shoppingCart({ id: this.$store.state.order.cartList.id })
        .then(res => {
          this.cartList = res;
          this.setCartList(res);
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.sell-ticket {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: auto;
  min-height: 0;
}

/* 可以设置不同的进入和离开动画 */
.slide-fade-enter-active {
  transition: all 0.3s;
}
.slide-fade-enter {
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}
</style>
