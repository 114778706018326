<template>
  <div class="section">
    <div class="flex-start align-center">
      <!-- 景区选择 -->
      <a-select
        @change="scenicChange"
        ref="selectsub"
        v-model="subScenicId"
        class="mr-4x"
        style="width: 120px"
      >
        <a-select-option
          :value="item.subScenicId"
          v-for="item in subScenics"
          :key="item.subScenicId"
          >{{ item.subScenicName }}</a-select-option
        >
      </a-select>
      <!-- 产品分类 -->
      <a-select
        @change="productChange"
        ref="selectgre"
        v-model="productClassid"
        style="width: 120px"
      >
        <a-select-option
          :value="temp.id"
          v-for="temp in productClass"
          :key="temp.id"
        >
          {{ temp.name }}
        </a-select-option>
      </a-select>
    </div>
    <!-- 产品查询 -->
    <div class="search searchfirst">
      <label>产品查询</label>
      <input
        class="search-inpt"
        @focus="bule1 = true"
        @blur="bule1 = false"
        v-model="name"
        type="text"
        placeholder="请输入商品条码/产品名称"
      />
      <!-- 清除icon -->
      <i
        @click="close"
        v-show="name.length"
        class="iconfont icontuige mr-1x font-xxl"
      ></i>
      <span class="font-xl" :class="{ bule: bule1 }">|</span>
      <!-- 右边搜索按钮 -->
      <div @click="search" class="search-icon" :class="{ bule: bule1 }">
        <a-icon type="search" class="font-xl mr-x" :class="{ bule: bule1 }" />
        搜索
      </div>
    </div>
    <div class="search">
      <label>商品扫码</label>
      <input
        v-model="barCode"
        type="text"
        placeholder="请扫描商品条码"
        @keydown.enter="scanEnter"
        @focus="bule2 = true"
        @blur="bule2 = false"
      />
      <span class="font-xl" :class="{ bule: bule2 }">|</span>
      <!-- 右边搜索按钮 -->
      <div class="search-icon" :class="{ bule: bule2 }">
        <a-icon type="scan" class="font-xl mr-x" :class="{ bule: bule2 }" />
        搜索
      </div>
    </div>
  </div>
</template>

<script>
import { subScenics as getSubScenic } from "../../../../api/merchant";
import { productMerchantCategoryList } from "../../../../api/product";
export default {
  name: "ProductFilter",
  created() {
    this.loadScenics();
    this.loadProductClass();
  },
  data() {
    return {
      subScenics: [], // 业态下拉框
      productClass: [], // 分类下拉框
      subScenicId: "", // 默认选中的 业态id
      classId: "", // 产品分类id
      name: "", // 商品名称 商品条码
      barCode: "",
      bule1: false,
      bule2: false,
      productClassid: ""
    };
  },
  methods: {
    // 获取 业态列表
    async loadScenics() {
      const { subScenics } = await getSubScenic({ filterSubScenic: true });
      this.subScenics = subScenics;
      this.subScenicId = subScenics[0].subScenicId;
    },
    // 获取分类列表
    async loadProductClass() {
      // type 类型说明
      // 1门票，2二销产品，3资产类型，4项目，5方案，6区域，7价格等级（票档），8剧场票分类，9剧目属性，10游乐产品分类，11住宿产品分类，12餐饮菜品分类，13零售产品分类，14餐饮原材料，15餐饮桌台，16餐饮区域，17组合产品，18剧场通票
      const res = await productMerchantCategoryList({ type: 13 });
      this.productClass = res;
      this.productClass.unshift({
        name: "全部分类",
        id: ""
      });
      this.productClassid = this.productClass[0].id;
      this.change();
    },
    // 改变业态触发
    scenicChange(value) {
      this.subScenicId = value;
      this.change();
    },
    // 改变分类触发
    productChange(value) {
      this.classId = value;
      this.change();
    },
    // 点击搜索触发
    search() {
      if (!this.name) {
        this.$message.warning("请输入商品条码/产品名称");
        return;
      }
      this.change(0);
    },
    // 扫码触发
    scanEnter() {
      if (this.barCode == "") return;
      this.change(1);
      let t = setTimeout(() => {
        this.barCode = "";
        clearTimeout(t);
        t = null;
      }, 100);
    },
    close() {
      this.name = "";
      this.change(0);
    },
    change(num) {
      let obj = {
        subScenicId: this.subScenicId,
        categoryId: this.classId,
        name: this.name,
        barCode: this.barCode,
        num
      };
      this.$emit("change", obj);
    }
  }
};
</script>

<style lang="less" scoped>
.section {
  padding: 14px;
  display: flex;
  align-items: center;
  .flex-between {
    width: 25%;
  }
  .search {
    position: relative;
    line-height: 32px;
    .search-inpt {
      padding: 0px 133px 0 32px;
    }
    .icontuige {
      color: #6ba4ff;
      position: absolute;
      right: 22%;
      top: 0;
      cursor: pointer;
    }
    input {
      height: 32px;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #d9d9d9;
      margin-left: 20px;
      padding: 0 85px 0 32px;
      &:focus {
        transition: 0.3s;
        outline: none;
        border: none;
        border: 1px solid #6ba4ff;
        box-shadow: 0px 0px 4px 0px #6ba4ff;
      }
    }
    span {
      position: absolute;
      right: 20%;
      top: 0;
      color: #d9d9d9;
    }
    .search-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: absolute;
      color: #d9d9d9;
      right: 4%;
      top: 0;
      font-size: 14px;
    }
  }
  .searchfirst {
    margin: 0 32px;
  }
}
.bule {
  color: #6ba4ff !important;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c7c7c7;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c7c7c7;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c7c7c7;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c7c7c7;
}
</style>
