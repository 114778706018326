<template>
  <div class="section">
    <div class="font-20">购物信息</div>
    <!--列表-->
    <div class="list-box">
      <a-row class="th">
        <a-col :span="2" class="td">序号</a-col>
        <a-col :span="5" class="td">产品名称</a-col>
        <a-col :span="5" class="td">商户名称</a-col>
        <a-col :span="5" class="td">价格</a-col>
        <a-col :span="4" class="td">数量</a-col>
        <!--        <a-col :span="3" class="td">默认凭证名称</a-col>-->
        <!--        <a-col :span="4" class="td">默认凭证信息</a-col>-->
        <a-col :span="3" class="td">操作</a-col>
      </a-row>

      <a-form-model
        layout="inline"
        :model="formData"
        ref="formData"
        v-if="formData.cartList.length"
      >
        <div
          class="t-body"
          v-for="(item, index) in formData.cartList"
          :key="item.id"
        >
          <a-row class="tr">
            <a-col :span="2" class="td">1</a-col>
            <a-col :span="5" class="td">{{ item.productName }}</a-col>
            <a-col :span="5" class="td">{{ item.ownerMerchantName }}</a-col>
            <a-col :span="5" class="td">
              <!--              零售无拼团-->
              <!--              <div class="pin">拼</div>-->
              <div
                class="flex-start align-center"
                v-show="showSureIndex === index"
              >
                <a-input
                  v-model="item.sellingAmount"
                  placeholder="请填写价格"
                  @blur="priceSearch(item)"
                />
                <span
                  class="flex-shrink pointer ph-1x"
                  @click="priceSearch(item)"
                  >确认</span
                >
              </div>
              <div v-if="showSureIndex !== index">
                <span>¥{{ item.sellingAmount }}</span>
                <del class="disabled mh-1x">¥{{ item.receivedAmount }}</del>
                <a-button
                  v-if="isEditPrice"
                  type="primary"
                  ghost
                  @click="changePrice(index)"
                  >修改价格</a-button
                >
              </div>
            </a-col>
            <a-col :span="4" class="td">
              <a-icon
                type="minus-circle"
                class="minus"
                v-show="item.buyNum > 0"
                @click="changeNum(item.buyNum - 1, item)"
              />
              <a-icon
                v-show="item.buyNum === 0"
                type="minus-circle"
                class="minus-disabled"
              />
              <input
                class="input-number"
                v-model="item.buyNum"
                @blur="e => changeNum(e.target.value, item)"
                onkeyup="this.value=this.value.replace(/\D/g,'')"
              />
              <a-icon
                type="plus-circle"
                theme="filled"
                class="plus"
                @click="changeNum(item.buyNum + 1, item)"
              />
            </a-col>
            <a-col :span="3" class="td">
              <p class="del flex-shrink P-1x" @click="delItem(item, 0)">
                删除
              </p>
            </a-col>
          </a-row>
        </div>
      </a-form-model>

      <a-empty v-else :image="simpleImage" />
    </div>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import { checkPhone, checkIdCard, checkText, fix2Reg } from "@/utils/global";
import { roleHighAuth } from "@/api/user";
export default {
  name: "ProductPlayer",
  props: {
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "" // 子景区id
        };
      }
    }
  },
  data() {
    return {
      showSureIndex: "",
      checkPhone,
      checkIdCard,
      checkText,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      cartId: 0,
      formData: {
        cartList: []
      },
      currentItem: {}, // 记录当前点击修改价格的产品
      isEditPrice: false // 是否有权限修改价格
    };
  },
  created() {
    roleHighAuth().then(res => {
      this.isEditPrice = res.whetherChangePrice || false;
    });
  },
  methods: {
    priceSearch(item) {
      this.currentItem = item;
      if (!fix2Reg.test(item.sellingAmount)) {
        this.$message.error(
          "自定义减价校验不通过，请输入数字，最多保留2位小数"
        );
        this.callback();
        return;
      }
      const obj = {
        productId: item.productId,
        index: item.index,
        changePrice: Math.round(item.sellingAmount * 10000) / 100 // 元转分正确写法
      };
      this.showSureIndex = "";
      this.$emit("change-price", obj, this.callback);
    },
    // 报错或无权限修改价格下调用
    callback() {
      this.currentItem.sellingAmount = this.currentItem.sellingAmountOld;
    },
    changePrice(index) {
      this.showSureIndex = index;
    },
    updateCart({ totalProductList, id }) {
      this.cartId = id;
      this.formData.cartList = totalProductList.map(item => {
        return {
          index: item.index, // 序号
          productId: item.productId,
          spuId: item.spuId,
          productName: item.productName, // 产品名称
          ownerMerchantName: item.ownerMerchantName, // 商户名称
          sellingAmount: item.sellingAmount / 100, // 销售价格
          sellingAmountOld: item.sellingAmount / 100, // 销售价格记录
          receivedAmount: item.receivedAmount / 100, // 价格
          buyNum: item.productFlagPO.buyNum // 数量
        };
      });
    },
    // 删除购物车数量
    delItem(item, val) {
      const obj = {
        productId: item.productId,
        index: item.index,
        detailBuyNum: val
      };
      this.$emit("update-cart", obj);
    },
    // 修改数量
    changeNum(value, item) {
      let val = value;
      if (val || val == "0") {
        const obj = {
          productId: item.productId,
          index: item.index,
          subScenicId: this.params.subScenicId,
          detailBuyNum: val
        };
        this.$emit("update-cart", obj);
      }
    },
    // 校验购物车表单
    validate() {
      let ok = true;
      // 如果购物车需要填游客信息则需要校验游客信息合法性
      if (this.$refs.formData) {
        this.$refs.formData.validate(valid => {
          if (!valid) {
            this.$message.warning("请正确填写购物信息");
            ok = false;
          }
        });
      }
      return ok;
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../../components/sell/ticket-order-common";
.pin {
  margin-right: 8px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 4px;
  background: #ffa32c;
  color: #fff;
}

/*加减号*/
.minus {
  color: #a4a4a4;
  font-size: 24px;
  cursor: pointer;
}

.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
  background: #f1f1f1;
}
/*减号*/
.plus {
  font-size: 24px;
  color: #25a2f2;
  cursor: pointer;
}

.not-allow {
  .minus,
  .plus,
  .input-number {
    cursor: not-allowed;
  }
}
.minus-disabled {
  color: #a4a4a4;
  font-size: 24px;
  cursor: not-allowed;
}
.ant-form-inline .ant-form-item {
  margin-right: 0;
  width: 100%;
  /deep/.ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
