<!--
@name: -零售新版-
@date: 2022-05-11
@author: 易远胜
-->
<template>
  <div>
    <div
      v-show="!showMember && !showPayOnly"
      class="flex-between flex-col wrapper-new"
    >
      <!--body-->
      <div class="main">
        <!--筛选条件-->
        <product-filter @change="updateChange"></product-filter>

        <!--门票列表+购买信息-->
        <product-list ref="productList" @addcart="updateCart"></product-list>

        <!--购物信息-->
        <product-player
          ref="productPlayer"
          :params="params"
          @update-cart="updateCart"
          @update-player="updatePlayer"
          @change-price="changePrice"
        ></product-player>

        <!--销售记录-->
        <product-sale-record
          ref="productSaleRecord"
          class="mb-2x"
        ></product-sale-record>
      </div>
      <!--底部下单按钮-->
      <ProductCalcFooter
        ref="productCalcFooter"
        @clear="clearCart"
        @member="showMember = true"
        @pay-only="showPayOnly = true"
        @change-cart="changeCart"
        @success="success"
        :permissionList="permissionLists"
        :permissionbutton="permissionButtonObj"
        business-type="1365211690130337793"
      />
    </div>

    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-if="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>

    <!--直接付款-->
    <pay-only :visible.sync="showPayOnly"></pay-only>
  </div>
</template>

<script>
import ProductList from "./components/ProductList";
import ProductPlayer from "./components/ProductPlayer";
import ProductSaleRecord from "../../../components/sell/ProductSaleRecord";
import ProductCalcFooter from "../../../components/sell/ProductCalcFooter.vue";
import MemberDetail from "../../home/member/MemberDetail.vue";
import PayOnly from "../../../components/sell/commonents/PayOnly";
import ProductFilter from "./components/ProductFilter";
import { productFrontSilkyTicket } from "../../../api/search";
import {
  postTicketAddBuy,
  ticketFast,
  retailTraveller,
  retailChangePrice,
  addRetailCart,
  updateRetailCart
} from "../../../api/cart";
import { mapGetters } from "vuex";
export default {
  name: "SellProduct",
  components: {
    ProductFilter,
    ProductList,
    ProductPlayer,
    ProductSaleRecord,
    ProductCalcFooter,
    MemberDetail,
    PayOnly
  },

  data() {
    return {
      productFrontSilkyTicket,
      permissionLists: {
        vip: "appNewHomeSellMember",
        directpayment: "appNewHomeSellCustomMoney",
        hangingorder: "appNewHomeSellEntryOrder",
        takeorder: "appNewHomeSellTakeTicket",
        paymentmethod: "appNewHomeSellMemberPaymentmethod"
      },
      permissionButtonObj: {
        wholeorderdiscount: "appNewHomeWholeorderdiscount",
        memberdiscount: "appNewHomeMemberdiscount",
        coupon: "appNewHomecoupon",
        marketingactivities: "appNewHomeMarketingactivities",
        integraldeduction: "appNewHomeIntegraldeduction"
      },
      params: {
        subScenicId: "" // 子景区id
        // playDate: "", // 游玩日期
        // appointmentRules: "",
        // timeId: "",
        // name: "", // 产品名称
        // categoryId: "" // 分类id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false
    };
  },
  computed: {
    // ...mapGetters("common", ["memberInfo"]),
    ...mapGetters({
      memberInfo: "common/memberInfo",
      // 页面布局-团体下单
      getSettings: "setting/getSettings"
    })
  },

  methods: {
    // 头部查询 获取数据
    updateChange(obj) {
      this.params.subScenicId = obj.subScenicId;
      this.$refs.productList.getSearchRetailGoodslist(obj);
    },
    // 添加/更新到购物车 - 调接口
    updateCart(obj) {
      // 是否立即核销，1是0不是
      obj.writeOffRightNow = 1;
      // 添加购物车
      let api = addRetailCart;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = updateRetailCart;
      }
      // // 添加会员信息
      // else {
      //   // 如果已经有会员信息
      //   if (this.memberInfo) {
      //     obj.purchaserMemberId = this.memberInfo.id;
      //   }
      //   // 如果本地存有默认打印方式
      //   if (this.getSettings.PrintType) {
      //     obj.cartPrintType = this.getSettings.PrintType;
      //   }
      // }
      api(obj)
        .then(res => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = cart;
      this.broadcast();
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.productList.updateNum(this.cart);
      this.$refs.productPlayer.updateCart(this.cart);
      this.$refs.productCalcFooter.updateCart(this.cart);
    },
    // 加购更新
    changeAddBuy(obj) {
      postTicketAddBuy({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.cart = res;
          this.broadcast();
        })
        .catch(() => {});
    },
    // 更新产品价格
    changePrice(obj, callback) {
      retailChangePrice({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {
          // 无权限或接口报错时候，恢复改价之前的价格
          callback();
        });
    },
    // 更新游客信息
    updatePlayer(data) {
      retailTraveller(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 更新快速通行
    updateFast(data) {
      ticketFast(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [], // 需要出游人的产品
        productList: []
      };
      this.broadcast();
    },
    // 支付成功
    success() {
      // 刷新今日销售
      this.$refs.productSaleRecord.loadOrderSalesrecord();
      // 支付成功
      this.clearCart();
      // 下单后清空会员信息
      this.$store.commit("common/UpdateMember", { id: "" });
    },
    // 校验购物车表单
    validate() {
      return this.$refs.productPlayer.validate();
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/less/custom";
@import "../../../components/sell/ticket-order-common";
.wrapper-new {
  box-sizing: border-box;
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow: auto;
  transition: height 0.3s ease-in-out;
  .main {
    margin-left: 16px;
    flex-grow: 1;
    overflow-y: auto;
    background: #f5f5f5;
  }
}
</style>
