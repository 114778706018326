<template>
  <!--三级路由-->
  <router-view />
</template>
<script>
export default {
  name: "TheRetail"
};
</script>
<style lang="less" scoped></style>
