<template>
  <div>
    <div class="wrapper flex-between flex-col relative" v-show="!showMember">
      <!-- 头部搜索 -->
      <header class="header">
        <a-form layout="inline">
          <a-form-item label="商品分类">
            <a-cascader
              :field-names="{
                label: 'name',
                value: 'id',
                children: 'children'
              }"
              :options="typeOptions"
              placeholder="全部商品分类"
              @change="onChange"
              clearable
              style="width: 170px"
            />
          </a-form-item>
          <a-form-item label="商品搜索">
            <a-input-search
              placeholder="请输入条码/名称"
              @search="onSearch"
              v-inputfocus
              enter-button
              allowClear
              style="width: 200px"
            />
          </a-form-item>
          <a-form-item label="商品扫码">
            <a-input
              v-inputfocus
              v-model="productId"
              :allowClear="true"
              placeholder="请扫描商品条码"
              @pressEnter="scanAddGoods()"
              style="width: 180px"
            >
              <a-icon type="scan" slot="suffix" class="font-l primary" />
            </a-input>
          </a-form-item>
        </a-form>
      </header>
      <!-- 头部结束 -->
      <div class="main flex-grow mt-2x p-2x">
        <!-- 列表开始 -->
        <a-empty description="暂无数据" v-if="list.length === 0" />
        <a-row :gutter="[16, 16]">
          <a-col :xxl="8" :xl="12" :lg="24" v-for="item in list" :key="item.id">
            <div class="ticket-card" @click="addGoods(item)">
              <img
                alt="图片"
                :class="{
                  image: true,
                  'ticket-card-no-img': !isShowRetailImg
                }"
                :src="item.skuImages"
                v-if="item.skuImages"
              />
              <div
                class="noImage"
                :class="{
                  image: true,
                  'ticket-card-no-img': !isShowRetailImg
                }"
                v-if="!item.skuImages"
              ></div>
              <div class="flex-between flex-col flex-grow ml-2x">
                <p class="title text-1" v-html="item.name"></p>
                <p>{{ item.spuSpecInfo.barCode }}</p>
                <!-- 最新版设置，已经去掉 显示商品库存 设置 -->

                <!-- 原型要求：若库存为不限：只显示单位出来即可 -->
                <p>
                  {{
                    item.whetherInfiniteStock == 1
                      ? item.spuSpecInfo.unit
                      : `库存：${item.stock}/${item.spuSpecInfo.unit}`
                  }}
                </p>
                <p class="money">{{ item.sellingPrice | money }}</p>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- 列表结束 -->
      <div class="text-r pv-1x">
        <a-pagination
          v-model="page"
          :default-page-size="size"
          :total="total"
          :page-size-options="['50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
      <!-- 底部开始 -->
      <footer class="footer bd-t">
        <member-menu
          :vip="'appRetailSellOrderCalcVip'"
          @click="showMember = true"
        ></member-menu>
        <div
          class="menu"
          v-permission="'appRetailSellOrderCalcDirectcollection'"
          @click="$router.push('/home/sell/CustomAmount')"
        >
          直接收款<span class="f-key" v-if="shortcutKey">(F2)</span>
        </div>
        <div
          v-permission="'appRetailSellOrderCalcHangingorder'"
          class="menu"
          :class="{ disabled: !cartList.id }"
          @click="cartList.id ? handlePause() : showTips('pause')"
        >
          <span>挂单<span class="f-key" v-if="shortcutKey">(F3)</span> </span>
        </div>
        <div
          v-permission="'appRetailSellOrderCalcTakeorder'"
          class="menu"
          :class="{ disabled: !isEnableTakeOrderBtn }"
          @click="isEnableTakeOrderBtn ? toTakeOrderList() : showTips('take')"
        >
          <span>取单<span class="f-key" v-if="shortcutKey">(F4)</span> </span>
        </div>
      </footer>
      <!-- 底部结束 -->
    </div>

    <!-- 会员详情 -->
    <member-detail
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>
  </div>
</template>

<script>
import { productMerchantCategoryList } from "../../../api/product";
import { productFrontRetailproduct } from "../../../api/search";
import { onkeydown } from "@/utils/global";
import {
  addShoppingCart,
  shoppingCartList,
  updateShoppingCart,
  pauseCart
} from "@/api/cart";

import { mapActions, mapGetters } from "vuex";
import MemberMenu from "../../../components/member/MemberMenu.vue";
import MemberDetail from "../../home/member/MemberDetail";
export default {
  components: { MemberMenu, MemberDetail },
  name: "RetailSellerList",
  data() {
    return {
      showMember: false, // 展示会员
      searchCode: "",
      typeOptions: [],
      filterType: "",
      goodList: [], //零售商品列表
      productId: "", //商品扫码扫出的值
      carting: false, // 加入购物车中
      timer: null,
      page: 1,
      size: 50,
      total: 0
    };
  },
  watch: {},
  created() {
    //有cartList就是从取单回来的
    let cartList = localStorage.getItem("cartList");
    localStorage.setItem("cartList", "");
    if (cartList) this.$emit("updateCart", JSON.parse(cartList));
    //获取分类列表
    this.getTypeList();
    this.loadData();
    this.getShoppingCartList(); // 获取挂单购物车列表
    this.setEndOfPayConfig({ backUrl: "/retail", logoutMember: true });
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    //根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          // console.log("按了F1");
          // this.$router.push({ path: "/home/sell/MemberDetail" });
          this.showMember = true;
          break;

        case 113:
          // console.log("按了F2");
          this.$router.push({ path: "/home/sell/CustomAmount" });
          break;

        case 114:
          this.cartList.id ? this.handlePause() : this.showTips("pause");
          break;

        case 115:
          // console.log("按了F4");
          this.isEnableTakeOrderBtn
            ? this.toTakeOrderList()
            : this.showTips("take");
          break;

        case 116:
          console.log("按了F5");
          // this.$router.push("/home/ticket/GetTicket");
          break;

        case 117:
          console.log("按了F6");
          // this.$router.push("/home/ticket/TicketOperate");
          break;

        case 118:
          console.log("按了F7");
          // this.$router.push({ path: "/home/sell/OrderManage" });
          break;
        case 13:
          // console.log("按了键盘enter");
          if (!this.cartList.ticketNum)
            return this.$message.warning("请先选择商品");

          if (this.$store.state.common.windowType === "sellTicket") {
            //请求出游人信息
            //请求接口的方法
          } else {
            this.$router.push("/home/sell/OrderInfo");
          }
          break;

        default:
          break;
      }
    },
    // 获取挂单购物车列表
    getShoppingCartList() {
      shoppingCartList({ id: this.cartList.id })
        .then(res => {
          if (res.length > 0) {
            this.$store.commit("common/updateTakeOrderBtnStatus", true); // 更改vuex中的isEnableTakeOrderBtn的值为true
          } else {
            this.$store.commit("common/updateTakeOrderBtnStatus", false); // 更改vuex中的isEnableTakeOrderBtn的值为false
          }
        })
        .catch(() => {});
    },
    ...mapActions("car", ["SetCartId", "clearCar"]),
    ...mapActions("order", ["setCartList"]),
    loadData() {
      let data = {
        page: this.page,
        size: this.size,
        categoryId: this.filterType,
        keyword: this.searchCode
      };
      productFrontRetailproduct(data).then(res => {
        // console.log(res);
        this.total = res.total;
        this.goodList = res.records;
      });
    },
    getTypeList() {
      //通过中间层获取分类列表,type2是零售
      productMerchantCategoryList({
        type: 13
      }).then(res => {
        let result = this.changeTreeOption(res);
        this.typeOptions = result;
      });
    },
    onChange(value) {
      this.filterType = value[value.length - 1];
      this.loadData();
    },
    onSearch(value) {
      //console.log(value);
      this.searchCode = value;
      this.loadData();
    },
    // 条数改变
    sizeChange(current, size) {
      this.page = 1;
      this.size = size;
      this.loadData();
    },
    //扫码加购物车
    scanAddGoods() {
      if (this.carting) return;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.carting = true;
        if (this.productId) {
          this.addToCart({
            barCode: this.productId, // 产品id
            buyNum: 1, // 购买数量
            buyChannels: 6, // 购买渠道
            businessCategory: 1 // 业务类型
          })
            .then(res => {
              this.setCartList(res);
            })
            .catch(() => {})
            .finally(() => {
              this.carting = false;
              this.productId = "";
            });
        } else {
          this.$message.warning("请扫描商品条码，加入购物车");
        }
        clearTimeout(this.timer);
        this.timer = null;
      }, 100);
    },
    //点击加购物车
    addGoods(row) {
      let data = {
        productId: row.id, // 产品id
        spuId: row.spuId, // 产品的spuId
        buyNum: 1, // 购买数量
        buyChannels: 6, // 购买渠道
        businessCategory: 1 // 业务类型
      };
      this.addToCart(data)
        .then(res => {
          this.setCartList(res);
        })
        .catch(() => {});
    },
    // 添加至购物车
    addToCart(data) {
      let api = addShoppingCart;
      if (this.cartList.id) {
        api = updateShoppingCart;
        data.id = this.cartList.id;
      }
      return api(data);
    },
    // 显示提示语
    showTips(val) {
      if (val === "pause") {
        this.$message.warning("购物车无产品，不能挂单");
      } else if (val === "take") {
        this.$message.warning("无取单列表，不能取单");
      }
    },
    // 处理挂单状态
    handlePause() {
      if (this.cartList.id) {
        pauseCart({ id: this.cartList.id })
          .then(() => {
            // this.clearCar(); // 清空购物车缓存
            this.setCartList({});
            this.$emit("updateCart", {}); // 清空购物车显示
            this.$store.commit("common/updateTakeOrderBtnStatus", true); // 更改vuex中的isEnableTakeOrderBtn的值为true
          })
          .catch(() => {});
      }
    },
    // 去取单页面
    toTakeOrderList() {
      this.$router.push({
        path: "/home/sell/TakeOrderList",
        query: { cartId: this.cartList.id }
      });
    },
    //把列表数据转成树形数据
    changeTreeOption(res) {
      if (!res) return [];
      const newArray = [];
      for (let i = 0; i < res.length; i++) {
        if (res[i].parentId == 0) {
          this.toTree(res[i], res);
          newArray.push(res[i]);
        }
      }
      return newArray;
    },
    // 递归转化
    toTree(parent, list) {
      const children = [];
      for (let i = 0; i < list.length; i++) {
        if (parent.id == list[i].parentId) {
          this.toTree(list[i], list);
          children.push(list[i]);
        }
      }
      if (children.length > 0) {
        parent.children = children;
      }
    }
  },
  computed: {
    ...mapGetters({
      isShowRetailImg: "setting/getisShowRetailImg",
      isShowCommodityStocks: "setting/getisShowCommodityStocks",
      shortcutKey: "setting/getshortcutKey",
      isShowSoldOutItems: "setting/getisShowSoldOutItems"
    }),
    ...mapGetters("order", ["cartList"]),
    list() {
      return !this.isShowSoldOutItems
        ? this.goodList.filter(
            item => item.whetherInfiniteStock || item.stock > 0
          )
        : this.goodList;
    },
    // 查询是否启用取单按钮的值(默认值：false)
    isEnableTakeOrderBtn() {
      return this.$store.state.common.isEnableTakeOrderBtn;
    }
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.wrapper {
  margin-right: 0;
  .header {
    padding-right: 0;
  }
  .ticket-card {
    display: flex;
    height: 130px;
    box-sizing: border-box;
    padding: 12px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    &:hover,
    &:active {
      box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
    }
    .image {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      flex-shrink: 0; // 防止图片被压缩
    }
    .noImage {
      background: #eee;
    }
    .title {
      font-size: 18px;
    }
    .text-1 {
      width: fit-content;
    }
    .money {
      font-size: 22px;
    }
  }

  .ticket-card-no-img {
    display: none;
  }
  /*底部*/
  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    .menu {
      position: relative;
      width: 13%;
      padding: 8px 0;
      border: 1px solid @primary;
      border-radius: 20px;
      text-align: center;
      color: @primary;
      cursor: pointer;
      margin: 0 12px;
      &.action {
        color: #ffffff;
        background-color: @primary;
      }
      &.disabled {
        border-color: @disabled;
        color: @disabled;
        cursor: not-allowed;
        background: #f5f5f5;
      }
    }
  }
}
</style>
