<template>
  <div class="section">
    <ul v-if="list.length" class="list">
      <li
        class="item"
        v-for="item in list"
        :key="item.id"
        :class="productList.includes(item.id) ? 'active' : ''"
      >
        <div class="content" @click="addCart(item.id)">
          <img
            :src="
              item.skuImages ||
                'https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16279/1627956072463.png'
            "
            alt=""
          />
          <div class="right">
            <p>{{ item.name }}</p>
            <a-tag class="blue">{{ item.merchantName }}</a-tag>
            <div class="price font-xl">{{ item.sellingPrice | money }}</div>
          </div>
        </div>
      </li>
    </ul>
    <a-empty v-else :image="simpleImage" />
    <!-- 扫码多个产品弹窗 -->
    <a-modal
      :visible="visible"
      title="请选择产品"
      :footer="null"
      @cancel="visible = false"
    >
      <ul class="list listProp">
        <li
          class="item"
          v-for="item in list"
          :key="item.id"
          :class="productList.includes(item.id) ? 'active' : ''"
        >
          <div class="content" @click="addMoreCart(item.id)">
            <img
              :src="
                item.skuImages ||
                  'https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16279/1627956072463.png'
              "
              alt=""
            />
            <div class="right">
              <p>{{ item.name }}</p>
              <a-tag>{{ item.merchantName }}</a-tag>
              <div class="price font-xl">{{ item.sellingPrice | money }}</div>
            </div>
          </div>
        </li>
      </ul>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import { searchRetailGoodslist } from "../../../../api/search";
export default {
  name: "ProductList",
  data() {
    return {
      list: [], // 产品列表
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      visible: false,
      productList: []
    };
  },
  methods: {
    async getSearchRetailGoodslist(obj) {
      let { records } = await searchRetailGoodslist({
        page: 1,
        size: 9999,
        ...obj
      });
      // 扫码添加购物车
      if (obj?.num) {
        if (records.length === 0) {
          this.$message.warning("产品不存在 请检查条码是否正确");
          return;
        }
        // 并且是多个产品 打开弹窗 让用户选择
        if (records.length > 1) {
          this.visible = true;
          return;
        }
        this.addMoreCart(records[0].id);
      }
      this.list = records || [];
    },
    addCart(productId) {
      if (this.productList.includes(productId)) {
        return;
      }
      const obj = {
        productId: productId
      };
      this.$emit("addcart", obj);
    },
    addMoreCart(productId) {
      const obj = {
        productId: productId
      };
      this.$emit("addcart", obj);
      this.visible = false;
    },
    updateNum(cart) {
      let arr = [];
      if (!cart) return;
      cart.productList.forEach(item => {
        arr.push(item.productId);
      });
      this.productList = arr;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.section {
  margin-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;
}
.list {
  .item {
    display: inline-block;
    width: 428px;
    height: 100px;
    background: #ffffff;
    border-radius: 11px;
    border: 1px solid #e6e6e6;
    margin-right: 10px;
    padding: 14px 13px 13px 0;
    cursor: pointer;
    .content {
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
        border-radius: 8px;
        margin: 0 12px 0 11px;
      }
      .right {
        .ant-tag {
          margin: 3px 0 4px 0;
          background-color: #e4f6fc;
          color: #4170db;
          font-size: 10px;
        }
      }
    }
    &:hover {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.23);
    }
  }
  .active {
    border: 1px solid @primary;
  }
}

.listProp {
  text-align: center;
  max-height: 60vh;
  overflow-y: auto;
  .item {
    margin-bottom: 20px;
  }
}
</style>
